import React from "react"
import { graphql } from "gatsby"
import { Block } from "baseui/block"
import { Cell } from "baseui/layout-grid"
import { SEOPage } from "../components/SEO"
import Layout from "../components/layout"
import Container from "../components/UI/Container"
import FlushedGrid from "../components/FlushedGrid"
import { PostCard } from "../components/BlogComponents"

const TagRoute = ({ data, pageContext }) => {
  const { tag } = pageContext
  const { edges: posts, totalCount } = data.allMdx
  return (
    <Layout isSimple>
      <SEOPage title={tag} />
      <Block paddingTop="190px" marginLeft={0}>
        <Container>
          <div className="post-content">
            <h1 style={{ marginTop: "0", marginBottom: "0" }}>{tag}</h1>
          </div>
          <Block
            paddingTop="60px"
            position="relative"
            display="flex"
            flexWrap="wrap"
          />
          <FlushedGrid>
            {posts &&
              posts.map(({ node }, i) => (
                <Cell key={node.id} span={6}>
                  <PostCard post={node} num={i} isHome={false} />
                </Cell>
              ))}
          </FlushedGrid>
        </Container>
      </Block>
    </Layout>
  )
}

export default TagRoute

export const tagPageQuery =  graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          template: { eq: "blog-post" }
          publish: { eq: true }
          tags: { in: [$tag] }
        }
      }
    ) {
      totalCount
      edges {
        node {
          id
          body
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            author
            featured
            tags
            hero_image
          }
          imgixImage {
            fluid(maxWidth: 600) {
              ...GatsbyImgixFluid
            }
          }
        }
      }
    }
  }
`
